import React from "react"

import ApplicationIcon from "../../../images/icons/application.svg"
import IconCircle from "../../icon-circle/icon-circle"
import Tile from "../../tile/tile"
import TileContentSpacer from "../../tile/tile-content-spacer/tile-content-spacer"
import "./initiative-application.sass"

const InitiativeApplication = ({
  titleTranslatedString,
  textTranslatedString,
  linkTextTranslatedString,
  linkPath,
  iconComponent,
}) => {
  return (
    <div className="_fp-initiative-application">
      <Tile
        linkTextTranslatedString={linkTextTranslatedString}
        linkPath={linkPath}
        titleTranslatedString={titleTranslatedString}
      >
        <IconCircle
          iconComponent={iconComponent ?? <ApplicationIcon />}
          text={textTranslatedString}
        />
        <TileContentSpacer />
      </Tile>
    </div>
  )
}

export default InitiativeApplication
