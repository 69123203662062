import React from "react"

import useNav from "../../../hooks/useNav"
import { removeLocaleFromUri } from "../../../i18n/removeLocaleFromUri"
import { useTranslation } from "../../../i18n/translate"
import useIsClient from "../../../util/is-client"
import ExpandableSiteNav from "../../expandable-site-nav/expandable-site-nav"
import SplitLayout from "../../layout/split-layout"
import PageHeader from "../../page-header/page-header"
import SideMenu from "../../side-menu/side-menu"

const SideNavView = ({
  children,
  titleTranslatedString,
  pathName,
  ...props
}) => {
  // Exceptions
  if (props.title) {
    throw new Error('"title" prop is deprecated')
  }
  //

  const isClient = useIsClient()

  const rootNav = useNav({
    path: removeLocaleFromUri(pathName),
    getRootLevel: true,
  })
  const nav = useNav({ path: removeLocaleFromUri(pathName) })
  const parentNav = rootNav.getParentOf({
    path: removeLocaleFromUri(pathName),
  })

  console.log(nav, parentNav, pathName)

  const { t } = useTranslation()

  const isThirdLevel = nav && nav.level === 2 && isClient

  return (
    <SplitLayout
      mobileHeader={() => {
        return (
          <>
            {isThirdLevel ? (
              parentNav.children && parentNav.children.length > 0 ? (
                <ExpandableSiteNav
                  nav={parentNav}
                  navTitleTranslatedString={titleTranslatedString}
                  addOverview={true}
                />
              ) : (
                ""
              )
            ) : nav && nav.children && nav.children.length > 0 ? (
              <ExpandableSiteNav nav={nav} />
            ) : (
              ""
            )}
          </>
        )
      }}
      header={
        <PageHeader
          levelTwoTitle={
            isThirdLevel ? t(parentNav.translationKey) : titleTranslatedString
          }
          levelOneTitle={t(rootNav.translationKey)}
          levelThreeTitle={titleTranslatedString}
          semanticHierarchy={isThirdLevel ? "h2" : "h1"}
        />
      }
      sidebar={<SideMenu nav={rootNav} pathName={pathName} />}
    >
      {children}
    </SplitLayout>
  )
}

export default SideNavView
