import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import LocalizedLink from "../../i18n/localized-link"
import { useTranslation } from "../../i18n/translate"
import ArrowDownIcon from "../../images/icons/arrow_down.svg"
import Heading from "../heading/heading"
import "./expandable-site-nav.sass"

const ExpandableSiteNav = ({
  nav,
  navTitleTranslatedString,
  addOverview = false,
  ...props
}) => {
  // Exceptions
  if (props.navTitle) {
    throw new Error('"navTitle" prop is deprecated')
  }
  //

  const [expanded, setExpanded] = useState(false)

  const itemsRef = useRef()

  let navHeight = 0
  if (itemsRef.current) {
    navHeight = itemsRef.current.clientHeight
  }

  const items = []

  if (addOverview && nav.url === nav.srcUrl) {
    items.push({ ...nav, name: { key: "GLOBAL-OVERVIEW" } })
  }

  items.push(...nav.children)

  const { t } = useTranslation()
  return (
    <div
      className={classNames("_fp-expandable-site-nav", {
        "_fp-expandable-site-nav--expanded": expanded,
      })}
    >
      <button
        onClick={() => setExpanded(!expanded)}
        className={"_fp-expandable-site-nav__title-wrapper"}
      >
        <div className="_fp-global-container">
          <div className="_fp-expandable-site-nav__title-wrapper__title">
            <Heading type={addOverview ? "h1" : "div"} className="_fp-text">
              {navTitleTranslatedString || t("GLOBAL-OVERVIEW")}
            </Heading>
            <div className="_fp-expandable-site-nav__title-wrapper__title__icon">
              <ArrowDownIcon />
            </div>
          </div>
        </div>
      </button>

      <div
        style={{ height: expanded ? navHeight + "px" : "0px" }}
        className="_fp-expandable-site-nav__nav-wrapper"
      >
        <div className="_fp-expandable-site-nav__nav-wrapper__nav">
          <div className="_fp-global-container">
            <div
              ref={itemsRef}
              className="_fp-expandable-site-nav__nav-wrapper__nav__items"
            >
              {items.map((child, index) => {
                return (
                  <div
                    className={
                      "_fp-expandable-site-nav__nav-wrapper__nav__items__item"
                    }
                    key={"expandable-site-nav-item-" + index}
                  >
                    <LocalizedLink
                      className={"_fp-link"}
                      activeClassName={"_fp-link--active"}
                      matchUrl={child.srcUrl}
                      exactMatch={true}
                      to={child.url}
                    >
                      {t(child.name.key)}
                    </LocalizedLink>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ExpandableSiteNav.propTypes = {
  nav: PropTypes.object,
  navTitleTranslatedString: PropTypes.string,
}

export default ExpandableSiteNav
