import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { useMeasure } from "react-use"

import { ExpandableContainer } from "../../expandable-container/expandable-container"
import DefaultGallery from "../../gallery/default-gallery/default-gallery"
import { GalleryItem } from "../../gallery/model/gallery-item"
import RichText from "../../rich-text/rich-text"
import "./text-with-or-without-gallery.sass"

const NoopWrapper = ({ children, ...props }) => <div {...props}>{children}</div>
const TextWithOrWithoutGallery = ({
  gallery,
  gallerySize,
  textTranslationKey,
  imageSide,
  centerText,
  expandableText,
}) => {
  const [ref, { height }] = useMeasure()

  const TextWrapper = expandableText ? ExpandableContainer : NoopWrapper
  return (
    <div
      className={classNames(
        "_fp-text-with-or-without-gallery",
        {
          "_fp-text-with-or-without-gallery--image-right":
            imageSide === "right",
        },
        {
          "_fp-text-with-or-without-gallery--large-gallery":
            gallerySize === "large",
        },
        {
          "_fp-text-with-or-without-gallery--center-text": centerText,
        },
        { "_fp-text-with-or-without-gallery--no-image": gallery.length <= 0 },
      )}
    >
      {gallery && gallery.length > 0 && (
        <div className={"_fp-text-with-or-without-gallery__gallery"} ref={ref}>
          <DefaultGallery items={gallery} showLargePreview />
        </div>
      )}
      <div className="_fp-text-with-or-without-gallery__text">
        <TextWrapper
          showOverlay={true}
          showCollapseOverlay={true}
          height={height ?? 400}
        >
          <div className={"_fp-text"}>
            <RichText textTranslationKey={textTranslationKey} />
          </div>
        </TextWrapper>
      </div>
    </div>
  )
}

TextWithOrWithoutGallery.propTypes = {
  gallery: PropTypes.arrayOf(PropTypes.instanceOf(GalleryItem)),
  textTranslationKey: PropTypes.string,
  imageSide: PropTypes.oneOf(["left", "right"]),
  gallerySize: PropTypes.oneOf(["regular", "large"]),
  centerText: PropTypes.bool,
  expandableText: PropTypes.bool,
}
TextWithOrWithoutGallery.defaultProps = {
  imageSide: "left",
  gallerySize: "regular",
}

export default TextWithOrWithoutGallery
