import React from "react"

import { useTranslation } from "../../../i18n/translate"
import TileContentSpacer from "../../tile/tile-content-spacer/tile-content-spacer"
import "./recruiting-process-steps.sass"

const RecruitingProcessSteps = () => {
  const { t } = useTranslation()

  const steps = [
    "CAREER-RECRUITING_PROCESS-RECRUITING_PROCESS_STEPS-STEP_1",
    "CAREER-RECRUITING_PROCESS-RECRUITING_PROCESS_STEPS-STEP_2",
    "CAREER-RECRUITING_PROCESS-RECRUITING_PROCESS_STEPS-STEP_3",
    "CAREER-RECRUITING_PROCESS-RECRUITING_PROCESS_STEPS-STEP_4",
    "CAREER-RECRUITING_PROCESS-RECRUITING_PROCESS_STEPS-STEP_5",
  ]

  return (
    <div className={"_fp-recruiting-process-steps"}>
      {steps.map((step, index, arr) => {
        return (
          <div key={index} className={"_fp-recruiting-process-steps__step"}>
            <p
              className={
                "_fp-heading-3 _fp-recruiting-process-steps__step__index"
              }
            >
              {index + 1}
            </p>
            <p
              className={
                "_fp-heading-4 _fp-recruiting-process-steps__step__text"
              }
            >
              {t(step)}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default RecruitingProcessSteps
