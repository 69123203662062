import classNames from "classnames"
import React from "react"

import { withTranslation } from "../../../../i18n/translate"
import RichText from "../../../rich-text/rich-text"
import Tile from "../../../tile/tile"
import TileContentSpacer from "../../../tile/tile-content-spacer/tile-content-spacer"
import RecruitingProcessSteps from "../recruiting-process-steps"
import "./recruiting-process-steps-tile.sass"

class RecruitingProcessStepsTile extends React.Component {
  render() {
    const { t } = this.props

    return (
      <div className={classNames("_fp-recruiting-process-steps-tile")}>
        <Tile
          titleTranslatedString={t(
            "CAREER-RECRUITING_PROCESS-RECRUITING_PROCESS",
          )}
        >
          <RecruitingProcessSteps />
          <TileContentSpacer size={"small"}></TileContentSpacer>
          <h4
            className={
              "_fp-heading-3 _fp-recruiting-process-steps-tile__conclusion"
            }
          >
            {t(
              "CAREER-RECRUITING_PROCESS-WE_LOOK_FORWARD_TO_RECEIVING_YOUR_APPLICATION",
            )}
          </h4>
        </Tile>
      </div>
    )
  }
}

export default withTranslation()(RecruitingProcessStepsTile)
