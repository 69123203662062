import { graphql } from "gatsby"
import React from "react"

import InitiativeApplication from "../../../components/career/initiative-application/initiative-application"
import RecruitingProcessStepsTile from "../../../components/career/recruiting-process-steps/recruiting-process-steps-tile/recruiting-process-steps-tile"
import TextWithOrWithoutGallery from "../../../components/dynamic-page-components/text-with-or-without-gallery/text-with-or-without-gallery"
import { GalleryPictureItem } from "../../../components/gallery/model/gallery-item"
import SEO from "../../../components/seo"
import Tile from "../../../components/tile/tile"
import TileContentSpacer from "../../../components/tile/tile-content-spacer/tile-content-spacer"
import SideNavView from "../../../components/views/side-nav-view/side-nav-view"
import useNav from "../../../hooks/useNav"
import { useTranslation } from "../../../i18n/translate"
import { dynamicImageWithGatsbyImage } from "../../../util/dynamic-image"

const ThesesPage = ({ location, pageContext, data }) => {
  const { t } = useTranslation()
  const openVacanciesNav = useNav({ uid: "open-vacancies" })

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("CAREER-THESES-META_TITLE")}
        descriptionTranslatedString={t("CAREER-THESES-META_DESCRIPTION")}
      />
      <SideNavView
        titleTranslatedString={t("MENU-CAREER-THESES_PAGE_TITLE")}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <Tile
              linkTextTranslatedString={t(
                "CAREER-OPEN_VACANCIES-VIEW_OPEN_VACANCIES",
              )}
              linkPath={
                openVacanciesNav.url + "?employmentTypes=educationstudy"
              }
            >
              <h2 className={"_fp-heading-1"}>{t("CAREER-THESES-TITLE")}</h2>
              <TileContentSpacer size={"extra-small"} />
              <h2 className={"_fp-heading-4"}>{t("CAREER-THESES-SUBTITLE")}</h2>
              <TileContentSpacer size={"extra-small"} />
              <TextWithOrWithoutGallery
                gallerySize={"large"}
                centerText={true}
                gallery={[
                  new GalleryPictureItem({
                    gatsbyFluidImage: dynamicImageWithGatsbyImage(
                      data.thesesImage,
                    ),
                  }),
                ]}
                textTranslationKey={"CAREER-THESES-TEXT"}
              ></TextWithOrWithoutGallery>
              <TileContentSpacer />
            </Tile>
          </div>
          <div className="_fp-col-12">
            <RecruitingProcessStepsTile />
          </div>
          <InitiativeApplication
            titleTranslatedString={t("CAREER-INITIATIVE_APPLICATION-TITLE")}
            linkPath={"https://munzing.softgarden.io/get-connected"}
            linkTextTranslatedString={t(
              "CAREER-INITIATIVE_APPLICATION-APPLY_NOW",
            )}
            textTranslatedString={t(
              "CAREER-INITIATIVE_APPLICATION-DESCRIPTION",
            )}
          />
        </div>
      </SideNavView>
    </>
  )
}

export default ThesesPage

export const query = graphql`
  {
    thesesImage: strapiDynamicImage(uid: { eq: "THESES" }) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
