import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../i18n/localized-link"
import { removeLocaleFromUri } from "../../i18n/removeLocaleFromUri"
import { withTranslation } from "../../i18n/translate"
import ArrowDownIcon from "../../images/icons/arrow_down.svg"
import isUrlActive from "../../util/isUrlActive"
import "./side-menu.sass"

class SideMenu extends React.Component {
  constructor(props) {
    super(props)

    let activeIndex = 0

    const { nav, pathName } = this.props

    const currentUrl = removeLocaleFromUri(pathName)

    nav.children.forEach((item, index) => {
      if (isUrlActive(currentUrl, item.srcUrl)) {
        activeIndex = index
      }
    })

    this.state = {
      items: nav.children,
      activeIndex: activeIndex,
      animate: false,
    }
  }

  componentDidMount() {
    this.setState({ items: this.props.nav.children })
    this.update()
  }

  setIndexActive(index) {
    this.setState({ activeIndex: index })
  }

  update() {
    if (document) {
      const navItems = document.querySelectorAll("._fp-side-menu-item")
      navItems.forEach((navItem) => {
        const navSubItem = navItem.querySelector(
          "._fp-side-menu-item__sub-items",
        )

        if (!navSubItem) {
          return
        }
        navItem.style.paddingBottom = navSubItem.scrollHeight + "px"
        navSubItem.style.height = navSubItem.scrollHeight + "px"
      })
    }
  }

  toggleChildren(index) {
    const currIndex = this.state.activeIndex

    let newIndex = index
    if (currIndex === newIndex) {
      newIndex = null
    }
    this.setState({ activeIndex: newIndex, animate: true })
  }

  render() {
    const { t } = this.props

    return (
      <div>
        {this.props.nav.children.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(
                "_fp-side-menu-item",
                {
                  "_fp-side-menu-item--hide-sub-items":
                    this.state.activeIndex !== index,
                },
                { "_fp-side-menu-item--animate": this.state.animate },
              )}
            >
              <div className="_fp-side-menu-item__top-item">
                <LocalizedLink
                  activeClassName={"_fp-side-menu-item__top-item__link--active"}
                  to={item.url}
                  matchUrl={item.srcUrl}
                  className={"_fp-side-menu-item__top-item__link"}
                >
                  {t(item.translationKey)}
                </LocalizedLink>
                {item.children && item.children.length > 0 && (
                  <button
                    className={"_fp-side-menu-item__top-item__icon-button"}
                    onClick={() => {
                      this.toggleChildren(index)
                    }}
                  >
                    <div
                      className={
                        "_fp-side-menu-item__top-item__icon-button__icon"
                      }
                    >
                      <ArrowDownIcon />
                    </div>
                  </button>
                )}
              </div>
              {item.children && item.children.length > 0 && (
                <div className="_fp-side-menu-item__sub-items">
                  {item.children.map((subItem, index) => {
                    return (
                      <LocalizedLink
                        activeClassName={
                          "_fp-side-menu-item__sub-items__item--active"
                        }
                        className="_fp-side-menu-item__sub-items__item"
                        key={`sub_item_${index}`}
                        matchUrl={subItem.srcUrl}
                        to={subItem.url}
                      >
                        {t(subItem.translationKey)}
                      </LocalizedLink>
                    )
                  })}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

SideMenu.propTypes = {
  nav: PropTypes.object.isRequired,
  pathName: PropTypes.string.isRequired,
}

export default withTranslation()(SideMenu)
