import PropTypes from "prop-types"
import React from "react"

import "./icon-circle.sass"

const IconCircle = ({ iconComponent, title, text }) => {
  return (
    <div className="_fp-icon-circle">
      <div className={"_fp-icon-circle__circle"}>
        {iconComponent && (
          <div className="_fp-icon-circle__circle__icon">{iconComponent}</div>
        )}
      </div>
      <div className={"_fp-icon-circle__content"}>
        <div
          className={
            "_fp-global-text-container _fp-icon-circle__content__container"
          }
        >
          {title && (
            <h3
              className={
                "_fp-heading-3 _fp-icon-circle__content__container__title"
              }
            >
              {title}
            </h3>
          )}
          <p className={"_fp-text _fp-icon-circle__content__container__text"}>
            {text}
          </p>
        </div>
      </div>
    </div>
  )
}

IconCircle.propTypes = {
  iconComponent: PropTypes.node.isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default IconCircle
